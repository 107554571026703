<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-avigora">
        <a href="" target="_blank" alt="Avigora">
          <img :src="avigoraPicture" alt="Avigora">
        </a>
        <span class="sep"></span>
        <p>Avigora offers you a formula of serious clairvoyance. At Avigora, we look above all for quality in clairvoyance, which is why we choose only the best clairvoyants, mediums and
          astrologers. Each counselor has a course of his own and specialties that we invite you to discover by consulting the presentation of each of our clairvoyants. Some use supports
          such as the pendulum, the tarot of Marseilles, the Oracle of Ge or the Oracle of Belline, others are pure mediums. If the choice of a counselor seems difficult and you are lost in
          the specialties of each one, do not hesitate to call us so that we help you to choose the adviser most adapted to your needs.</p>
      </div>
    </div>

    <div class="laptop-img avigora-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/avigora-main-img.png" alt="Avigora">
      </div>
    </div>

    <div class="container-about avigora-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/avigora-diamond.svg" alt="Avigora">
        <h3>About the project</h3>
        <p>A platform that gathers professionals from many fields under the same umbrella to offer a full consultancy website, their application includes multiple methods of contact, including video
          chat with the consultants.<br/>
          We have been contracted do refresh their homepage and help out with design services.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img src="@/assets/casestudy/opensans-font.svg" alt="Avigora Font">
            <h3><span style="color:#fd6114">Co</span><span style="color:#242782">lo</span><span style="color:#b02d2d">rs</span></h3>

            <div class="colors">
              <div class="fcolor avigora-p">Primary</div>
              <div class="scolor avigora-s">Secondary</div>
              <div class="tcolor avigora-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>
            <div class="color-codes">
              <div class="ccodes">
                <p class="primary-av">#fd6114</p>
                <p class="secondary-av">#242782</p>
                <p class="third-av">#b02d2d</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Summerwood'}" class="left">
              <img src="@/assets/casestudy/btn-sw.jpg" alt="Summerwood">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Vonino'}" class="right">
              <img src="@/assets/casestudy/btn-vonino.jpg" alt="Vonino">
            </router-link>
          </div>

        </div>
      </div>
    </div>

    <ContactUs color="#fd6114" colorTitle="#fafafa" colorButton="#fafafa"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import avigora from "@/assets/casestudy/avigora-logo.svg";
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Avigora",
  components: {LetsChat, ContactUs, Footer},
  data() {
    return {
      avigoraPicture: avigora
    }
  },
}
</script>

<style>
.logo-avigora img {
  max-width: 320px;
}

.avigora-laptop {
  background: #fd6114;
}

.avigora-p {
  background: #fd6114;
}

.avigora-s {
  background: #242782;
}

.avigora-t {
  background: #b02d2d;
}

.primary-av {
  color: #fd6114;
}

.secondary-av {
  color: #242782;
}

.third-av {
  color: #b02d2d;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #fd6114 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>